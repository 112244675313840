<template>
  <div class="cyt-car-select-wrapper" @blur="optionShown = false" ref="cytCardSelect"
       :class="optionShown ? 'active' : ''">
    <div class="cyt-car-select" @click="toggleOption">
      <span class="select-text">{{ selectedText ? selectedText : onlySeries ? '请选择品牌/车系' : '请选择品牌/车系/车型' }}</span>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="cyt-car-select-option-wrapper" v-show="optionShown" :style="upperOption ? {bottom: '88px'} : {top: '42px'}">
      <div class="letter-index-wrapper">
        <div class="letter-index-item" v-for="letter in LetterList" :key="letter"
             :class="{active: activeIndex === letter}"
             @click="onLetterIndexClick(letter)">{{ letter }}
        </div>
      </div>
      <div class="brand-wrapper" id="cytCardBrandWrapper">
        <div class="brand-group" v-for="letter in LetterList" :key="letter">
          <div class="group-title" :id="`letter-${letter}`">{{ letter }}</div>
          <div class="item-wrapper" v-for="brand in brandInfo[letter]" :key="brand.value"
               @click="onBrandClick(brand)"
               :class="{active: activeBrand.value === brand.value}">
            <span class="name">{{ brand.text }}</span>
            <i class="el-icon-loading" v-if="brandLoading && activeBrand.value === brand.value"></i>
            <i class="el-icon-arrow-right" v-else></i>
          </div>
        </div>
      </div>
      <div class="series-wrapper" v-if="Array.isArray(activeBrand.children)">
        <div class="item-wrapper" v-for="series in activeBrand.children" :key="series.value"
             @click="onSeriesClick(series)">
          <span class="name">{{ series.text }}</span>
          <i class="el-icon-loading" v-if="seriesLoading && activeSeries.value === series.value"></i>
          <i class="el-icon-arrow-right" v-else-if="!onlySeries"></i>
        </div>
        <span class="empty-message" v-if="activeBrand.children.length === 0 && !brandLoading">暂无数据</span>
      </div>
      <div class="model-wrapper" v-if="Array.isArray(activeSeries.children) && !onlySeries">
        <div class="item-wrapper" v-for="model in activeSeries.children" :key="model.value"
             @click="onModelClick(model)">
          <span class="name">{{ model.text }}</span>
        </div>
        <span class="empty-message" v-if="activeSeries.children.length === 0 && !seriesLoading">暂无数据</span>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import './styles/pc/pc.scss'
import {get} from "@/utils/axios";
import {getBrandInfo, getSeriesList, getModelList} from '@/utils/carInfo'

const LetterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#']
let onWindowClick = null
// brand 品牌 model 车型 series 车系
export default {
  name: 'CytCarSelect',
  props: ['defaultValue', 'onlySeries', 'upperOption'],
  data() {
    return {
      LetterList,
      optionShown: false,
      needScroll: false,
      brandInfo: {},
      activeIndex: 'A',
      activeBrand: {},
      activeSeries: {},
      brandLoading: false,
      seriesLoading: false,
      selectedText: '',
      selectedModelValue: ''
    }
  },
  mounted() {
    this.initBrand()
    const that = this;
    onWindowClick = e => {
      if (that.$refs.cytCardSelect && !that.$refs.cytCardSelect.contains(e.target)) {
        that.optionShown = false
      }
    }
    window.addEventListener('click', onWindowClick, false)
  },
  beforeDestroy() {
    window.removeEventListener('click', onWindowClick, false)
  },
  methods: {
    async initBrand() {
      //查询品牌
      this.brandInfo = await getBrandInfo()
      this.calcCarInfoWithProps(this.defaultValue)
    },
    onLetterIndexClick(letter) {
      this.activeIndex = letter
      console.log(this.$el.querySelector(`#letter-${letter}`).offsetTop)
      const offsetTop = this.$el.querySelector(`#letter-${letter}`).offsetTop
      this.$el.querySelector('#cytCardBrandWrapper').scrollTo(0, offsetTop)
    },
    async onBrandClick(brand) {
      if (this.activeBrand.value == brand.value || this.brandLoading) {
        return;
      }
      this.activeBrand = {...brand, children: brand.children || []}
      this.activeSeries = {}
      if (!brand.children) {
        // 如果该品牌未获取过车系数据 则调接口获取
        this.brandLoading = true
        const seriesList = await getSeriesList(brand.value)
        this.activeBrand.children = seriesList.slice()
        this.brandLoading = false
      }
    },
    async onSeriesClick(series) {
      if (this.activeSeries.value === series.value || this.seriesLoading) {
        return;
      }
      this.activeSeries = {...series, children: series.child || []}
      if (this.onlySeries) {
        this.selectedText = `${this.activeBrand.text}/${this.activeSeries.text}`
        this.selectedModelValue = series.value
        this.optionShown = false
        this.$emit('carSelected', [this.activeBrand.value, series.value])
      } else if (!series.children) {
        // 该车系未获取过车型信息，需要调用接口获取
        this.seriesLoading = true
        const modelList = await getModelList(series.value)
        this.activeSeries.children = modelList.slice()
        this.seriesLoading = false
      }
    },
    onModelClick(model) {
      this.selectedText = `${this.activeBrand.text}/${this.activeSeries.text}/${model.text}`
      this.selectedModelValue = model.value
      this.optionShown = false
      this.$emit('carSelected', [this.activeBrand.value, this.activeSeries.value, model.value, model.text])
    },
    toggleOption() {
      this.optionShown = !this.optionShown
      if (this.optionShown && this.needScroll) {
        this.needScroll = false
        setTimeout(() => {
          this.onLetterIndexClick(this.activeIndex)
        }, 200)
      }
    },
    async calcCarInfoWithProps(newValue) {
      if (!Array.isArray(newValue)) {
        return;
      }
      const brandValue = newValue[0] || ''
      const seriesValue = newValue[1] || ''
      const modelValue = newValue[2] || ''
      if (!brandValue) {
        return
      }
      for (let i = 0; i < Object.keys(this.brandInfo).length; i++) {
        const letter = Object.keys(this.brandInfo)[i]
        this.needScroll = true
        this.activeIndex = letter
        const brandList = this.brandInfo[letter]
        const brandIndex = brandList.findIndex(brand => brand.value === brandValue);
        if (brandIndex === -1) {
          continue;
        }
        const brand = brandList[brandIndex]
        this.onBrandClick(brand)
        if (!seriesValue) {
          return;
        }
        const seriesList = await getSeriesList(brand.value)
        const series = seriesList.find(series => series.value === seriesValue)
        if (!series) {
          return;
        }
        this.onSeriesClick(series)
        if (!modelValue) {
          return;
        }
        const modelList = await getModelList(series.value)
        const model = modelList.find(model => model.value === modelValue)
        if (!model) {
          return;
        }
        this.onModelClick(model)
      }
    }
  }
}
</script>
