import _ from 'lodash'
import {get} from "@/utils/axios";
var pinyin = require("pinyin");

let brandList = null

async function getBrandInfo() {
  if (brandList) {
    return brandList
  } else {
    const res = await get('/system/brand/list')
    if (res.code === 200 && Array.isArray(res.rows)) {
      const _groupList = res.rows.map((item, i) => {
        return {
          index: i,
          text: item.brandName,
          value: item.brandId,
          children: null,
        };
      })
      brandList = _.groupBy(_groupList, getBrandFirstLetter)
      return brandList
    } else {
      return {}
    }
  }
}

async function getSeriesList(value) {
  const res = await get(`/system/brand/list/s/${value}`)
  if (res.code === 200 && Array.isArray(res.data)) {
    return res.data.map((item, i) => {
      return {
        index: i,
        text: item.vehicleSeriesName,
        value: item.vehicleSeriesId,
        children: null
      };
    });
  }
  return []
}
async function getModelList(value) {
  const res = await get(`/system/brand/list/t/${value}`)
  if (res.code === 200 && Array.isArray(res.data)) {
    return res.data.map((item, i) => {
      return {
        text: item.vehicleTypeName,
        value: item.vehicleTypeId,
      };
    });
  }
  return []
}

function getBrandFirstLetter(item) {
  let groupIndex = '#'
  try {
    groupIndex = pinyin(item.text.slice(0, 1), {
      style: pinyin.STYLE_FIRST_LETTER,
    })[0][0].toUpperCase()
  } catch (e) {
    groupIndex = '#'
  }
  return groupIndex
}

export {
  getBrandInfo,
  getSeriesList,
  getModelList,
}
